<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
         <b-col lg="6" sm="12">
            <ValidationProvider name="Rta Name (En)" vid="rta_name_en" rules="required">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="rta_name_en"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('tradeTariffApp.rta_name')}} {{ $t('globalTrans.en')}}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="form.rta_name_en"
                  id="rta_name_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        <b-col lg="6" sm="12">
            <ValidationProvider name="Rta Name (Bn)" vid="rta_name_bn" rules="required">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="rta_name_bn"
                slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                {{ $t('tradeTariffApp.rta_name')}} {{ $t('globalTrans.bn')}}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="form.rta_name_bn"
                  id="rta_name_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Coverage of Agreement" vid="coverage_of_agreement_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="coverage_of_agreement_id"
                slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{ $t('tradeTariffApp.coverage_of_agreement')}} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                  plain
                  v-model="form.coverage_of_agreement_id"
                  :options="coverageOfAgreementList"
                  id="coverage_of_agreement_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Status of Agreement" vid="status_of_agreement_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="status_of_agreement_id"
                slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{ $t('tradeTariffApp.status_of_agreement')}} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                  plain
                  v-model="form.status_of_agreement_id"
                  :options="statusOfAgreementList"
                  id="status_of_agreement_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Current Signatories" vid="current_signatories">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="current_signatories"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                {{ $t('tradeTariffApp.current_signatories')}}
                </template>
                <v-select
                  id="current_signatories"
                  v-model="form.current_signatories"
                  multiple
                  :reduce="op => op.value"
                  :options="tradeTariffCountryList"
                  label="text"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </v-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Region" vid="region_id" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="region_id"
                slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{ $t('tradeTariffApp.region')}} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                  plain
                  v-model="form.region_id"
                  :options="regionList"
                  id="region_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="WTO Notification Year" vid="wto_notification_year" rules="required">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" label-for="wto_notification_year">
                <template v-slot:label>
                  {{ $t('tradeTariffApp.wto_notification_year') }} <span class="text-danger">*</span>
                </template>
                <date-picker
                  id="wto_notification_year"
                  v-model="form.wto_notification_year"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :class="errors[0] ? 'is-invalid' : ''"
                >
                </date-picker>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" sm="12">
                <ValidationProvider name="Attachment" vid="attachment_main" :rules="attachmentx1 ? '' : 'required'">
                  <b-form-group slot-scope="{ valid, errors }" label-for="attachment_main" >
                    <template v-slot:label>
                        {{$t('globalTrans.attachment')}}<span class="text-danger">* {{ $t('externalTradeTraiff.attachment_type') }}</span>
                    </template>
                    <div class="d-flex">
                      <b-form-file accept=".pdf, .docx, .doc, .xls, .xlsx" id="attachment_main" v-model="form.file"
                        @change="onFileChange" :state="errors[0] ? false : (valid ? true : null)"
                        :placeholder="attachmentx1 ? attachmentx1 : $t('globalTrans.attachment_placeholder')"
                        :drop-placeholder="$t('externalTradeTraiff.attachment_drop_placeholder')"></b-form-file>
                    </div>
                    <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { wtoRtoInfoManagementStore } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id', 'secretQuestionList'],
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      attachmentx1: '',
      unitLoading: false,
      form: {
        rta_name_en: '',
        rta_name_bn: '',
        coverage_of_agreement_id: 0,
        status_of_agreement_id: 0,
        current_signatories: [],
        region_id: 0,
        attachment_main: '',
        file: [],
        wto_notification_year: ''
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getEditInfo()
      this.form = tmp
      this.attachmentx1 = this.form.attachment_main.split('/')[3]
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    tradeTariffCountryList: function () {
      return this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.filter(item => item.status === 1)
    },
    regionList: function () {
      return this.$store.state.TradeTariffService.commonObj.regionList.filter(item => item.status === 1)
    },
    coverageOfAgreementList: function () {
      return this.$store.state.TradeTariffService.commonObj.coverageOfAgreementList
    },
    statusOfAgreementList: function () {
      return this.$store.state.TradeTariffService.commonObj.statusOfAgreementList
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localizeSeaPortsList(newVal)
      }
    }
  },
  methods: {
    onFileChange (event) {
        const input = event.target
        if (input.files && input.files[0]) {
          const reader = new FileReader()
          reader.onload = (e) => {
            this.form.attachment_main = e.target.result
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.form.attachment_main = ''
        }
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      const formData = this.form
      this.form.id = this.id ? this.id : 0
      result = await RestApi.postData(tradeTariffServiceBaseUrl, wtoRtoInfoManagementStore, formData)
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getEditInfo () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
