<template>
  <b-container fluid>
       <b-row style="line-height: 20px">
            <template>
                <b-row>
                    <b-col md="12">
                        <table class="table table-bordered">
                            <tr><th>{{ $t('tradeTariffApp.rta_name')}}</th><td>: {{ $i18n.locale === 'bn' ? items.rta_name_bn : items.rta_name_en }} </td></tr>
                            <tr><th>{{ $t('tradeTariffApp.coverage_of_agreement')}}</th><td>: {{ getCoverageOfAgreementName(items.coverage_of_agreement_id) }} </td></tr>
                            <tr><th>{{ $t('tradeTariffApp.status_of_agreement')}}</th><td>: {{ getStatusOfAgreementName(items.status_of_agreement_id) }} </td></tr>
                            <tr><th>{{ $t('tradeTariffApp.current_signatories')}}</th><td>:
                            <span v-for="(data,index) in items.current_signatories" :key="index">{{ getCurrentSignatoriesName(data) }}, </span>
                            </td></tr>
                            <tr><th>{{ $t('tradeTariffApp.region')}}</th><td>: {{ getRegionName(items.region_id) }} </td></tr>
                            <tr><th>{{ $t('globalTrans.attachment')}}</th><td>:
                            <span v-if="items.attachment_main !== null">
                            <a target="_blank" class="btn btn-primary mr-2" :href="tradeTariffServiceBaseUrl+'download-attachment?file='+items.attachment_main" title="Application Attachment"><i class="ri-file-download-line"></i>&nbsp;
                                        </a>
                                    </span>
                            <span v-else>N/A</span>
                             </td></tr>
                            <tr><th>{{ $t('tradeTariffApp.wto_notification_year')}}</th><td>: {{ items.wto_notification_year | dateFormat }} </td></tr>
                        </table>
                    </b-col>
                </b-row>
            </template>
    </b-row>
  </b-container>
</template>
<script>
import { tradeTariffServiceBaseUrl } from '@/config/api_config'

export default {
    props: ['items'],
    components: {
    },
    created () {
    },
    mounted () {
    },
    data () {
        return {
            tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
            ports: [],
            seaPortsList: [],
            landPortsList: [],
            airPortsList: []
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        getRegionName (id) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.regionList.find(item => item.value === parseInt(id))
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            } else {
            return ''
            }
        },
        getCurrentSignatoriesName (id) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.find(item => item.value === parseInt(id))
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            } else {
            return ''
            }
        },
        getCoverageOfAgreementName (id) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.coverageOfAgreementList.find(item => item.value === parseInt(id))
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            } else {
            return ''
            }
        },
        getStatusOfAgreementName (id) {
            const cateObj = this.$store.state.TradeTariffService.commonObj.statusOfAgreementList.find(item => item.value === parseInt(id))
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            } else {
            return ''
            }
        },
        getCountryName (id) {
            const obj = this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.find(item => item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
        },
        getCapitalName (id) {
            const obj = this.$store.state.TradeTariffService.commonObj.capitalInfoList.find(item => item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
        },
        getAirPortName (id) {
            var obj = this.ports
            if (obj) {
                obj = obj.find(item => item.id === parseInt(id))
                if (obj) {
                    return this.$i18n.locale === 'en' ? obj.port_name_en : obj.port_name_bn
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        getLandPortName (id) {
            var obj = this.ports
            if (obj) {
                obj = obj.find(item => item.id === parseInt(id))
                if (obj) {
                    return this.$i18n.locale === 'en' ? obj.port_name_en : obj.port_name_bn
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        getProductName (id) {
            const obj = this.$store.state.TradeTariffService.commonObj.productList.find(item => item.status === 1 && item.value === parseInt(id))
            if (obj) {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
            } else {
                return ''
            }
        }
    }
}

</script>
